import React  from 'react';
export default function Speak({ data }) {
    return (
        <div className='speak' style={{ background: `url('${data.backgroundImage}')` }}>
            <div className='content'>
                {data.title && <h2>{data.title}</h2>}
                {/* {data.video && <iframe width="1060" height="315" src="https://www.youtube.com/embed/KXkBZCe699A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>} */}
                {/* {data.url && <video poster='/logo.png' src={data.url} autoPlay controls  width={760} height={315}></video>} */}
                <span>Saitpoint Limited is a dynamic IT solutions provider dedicated to delivering innovative and customized technology services to clients across diverse industries. With a strong focus on regulatory compliance, clinical operations, and enterprise process automation, the company leverages advanced technologies like AI, cloud computing, and data analytics to solve complex business challenges. Saitpoint partners with leading organizations, including pharmaceutical and life sciences companies, to streamline operations, enhance efficiency, and drive digital transformation. Committed to excellence and client success, Saitpoint Limited is a trusted partner for businesses worldwide. </span>
            </div>
        </div>
    );
}