import React from 'react'
import Layout from '../components/Layout'
import layout from '../layouts'
let name="page"
export default function Page(props) {
    let a=[];
    a=layout[name].find(x=>x.name.toLowerCase()===props.match.params.pagename.toLowerCase())

    if(!a?.components)
    return <h1>coming soon</h1>

    return (
        <div>
            {a.components.map((layoutTemplate,index)=>{
                return <Layout {...layoutTemplate} />
            })}
        </div>
    )
}
