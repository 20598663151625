import React from "react";
import Slider from "react-slick";
export default function Slider2({data}) {
    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay:true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ],

    };
    return (
        <div class="ourclients">
        {(<Slider {...settings} >
            {data.map((slide, index) => <div className="slider2-container">
               
                <img alt="best in country saitpoint.net"  src={slide.image} />
              
            </div>
            )}
        </Slider>)}
        </div>
    );
}