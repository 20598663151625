const a={name:"diversity",components:[
    {component:"banner",data:{
        title:"Diversity and Inclusion                ",
        description:"Company accolades                ",
        image:"/sectionconfig/diversity.jpg"
    }},
    {component:"diversitycards",data:{pagination:false,data:[
        { id: 1, title: "Diversity and Inclusion", tags: "Empowering Innovation Through Diversity and Inclusion", image: "/sectionconfig/inclusive.png", desc: "Diversity and inclusion are core values for modern IT organizations, fostering a culture where individuals from varied backgrounds, perspectives, and experiences can thrive. By embracing gender, cultural, and cognitive diversity, IT companies unlock innovation, improve decision-making, and better serve global clients. Initiatives include equitable hiring practices, mentorship programs, and employee resource groups to ensure representation and support for underrepresented groups. This commitment to diversity and inclusion enhances workplace collaboration and strengthens client relationships, creating a more innovative and competitive organization that mirrors the diverse world it serves.", link: "/", hasicon: false, icon: "" },
        { id: 1, title: "", tags: "", image: "", desc: "Diversity and Inclusion (D&I) are fundamental principles for fostering equitable, respectful, and innovative environments. They emphasize embracing and valuing differences across various dimensions, including Gender - Promoting gender equality and empowering all genders to contribute equally in workplaces and communities. Education - Encouraging diverse educational backgrounds to bring varied perspectives and skills to problem-solving. Inclusive Workspaces - Creating safe, accessible, and supportive environments where everyone feels valued and included. Race and Ethnicity - Celebrating racial and cultural diversity, ensuring fair treatment, and addressing systemic biases. Language - Supporting multilingual communication and inclusivity to bridge gaps between individuals from different linguistic backgrounds. Sexual Orientation and Identity - Fostering acceptance of diverse sexual orientations and gender identities, and combating discrimination.", link: "/", hasicon: false, icon: "" },
        // { id: 2, title: "my-caption", tags: "Client stories", image: "/sectionconfig/client stories.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        // { id: 3, title: "my-caption", tags: "Meet our people", image: "/sectionconfig/meetourpeople.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        // { id: 4, title: "my-caption", tags: "Case studies & stories", image: "/sectionconfig/case.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        // { id: 5, title: "my-caption", tags: "Cloud Journey", image: "/sectionconfig/cloud journey.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        // { id: 6, title: "my-caption", tags: "Voices of Change", image: "/sectionconfig/voices.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        // { id: 7, title: "my-caption", tags: "Capabilities", image: "/sectionconfig/cap.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        // { id: 8, title: "my-caption", tags: "What We Do", image: "/sectionconfig/whatwedo.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        // { id: 9, title: "my-caption", tags: "Blogs", image: "/sectionconfig/blog.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
    ]}},
    {component:"partners",data:{pagination:false,data:[
        { id: 1, image: "/img/micro.png", },
        { id: 2, image: "/businesssectionconfig/greatplace.jpg", },
    ]}},

]}
export default a 
