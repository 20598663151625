const pagel= {name:"cloud",components:[
    {component:"banner",data:{
        title:"Learning and Recognitions                ",
        description:"Company accolades                ",
        image:"/sectionconfig/cloud.jpg"
    }},
    {component:"cards",data:{pagination:false,data:[
        { id: 1, title: "Cloud Services", tags: "Cloud Tools", image: "/sectionconfig/cloudser.jpg", desc: "Rapid enhanced scalability of resources to meet business demands, Cost Optimization reduced upfront capital expenditure by adopting pay-as-you-go models, Streamlined team collaboration using tools like Microsoft 365, Google Workspace, and Slack, Achieved quicker application and service deployment using CI/CD pipelines like Microsoft 365, Google Workspace, and Slack. Strengthened security using tools like AWS IAM, and Azure Active Directory, Automated routine tasks with Ansible, Terraform, and other cloud-native tools.", link: "/", hasicon: false, icon: "" },
        { id: 2, title: "Azure DevOps", tags: "DevOps", image: "/sectionconfig/devops.jpg", desc: "Our company experts specialize in delivering Azure DevOps and DevSecOps solutions, providing end-to-end automation and security for software development lifecycles. With expertise in Azure Boards, they enable efficient project tracking and agile workflows. Using Azure Repos, they manage version control for seamless collaboration, while Azure Pipelines ensure robust CI/CD implementation. They also excel in Testing, integrating automated testing frameworks for quality assurance, and Azure Artifacts, streamlining package management. By embedding DevSecOps practices, our team ensures secure, scalable, and efficient development processes tailored to meet organizational goals.", link: "/", hasicon: false, icon: "" },
        { id: 3, title: "AI", tags: "Artificial Intelligence", image: "/sectionconfig/aiservice.jpg", desc: "Our company’s AI/ML experts specialize in delivering advanced image analysis solutions, leveraging cutting-edge Artificial Intelligence (AI) and Machine Learning (ML) technologies. Develop systems that can automatically detect faces, identify landmarks, recognize logos, and extract insights from images. By applying deep learning models and computer vision techniques, our team enables organizations to unlock valuable data from visual content, improving accuracy, efficiency, and decision-making across various industries. We use frameworks like TensorFlow, PyTorch, and OpenCV to deliver powerful, scalable AI solutions tailored to specific business needs.", link: "/", hasicon: false, icon: "" },
        { id: 4, title: "CI/CD", tags: "CICD", image: "/sectionconfig/cicd.jpg", desc: "Our company experts excel in delivering end-to-end CI/CD solutions, enabling seamless software development and deployment processes. With expertise in Continuous Development, they ensure iterative progress in coding, Continuous Integration, automating code merges and testing; and Continuous Testing, integrating automated quality checks. They also specialize in Continuous Delivery to maintain deployment-ready code, Continuous Deployment for automated production releases, and Continuous Monitoring to track performance and security post-deployment. By utilizing leading tools like Jenkins, GitLab, Azure DevOps, and Docker, our team ensures faster, reliable, and efficient delivery pipelines tailored to business needs.", link: "/", hasicon: false, icon: "" },
        { id: 5, title: "Azure Services", tags: "Microsoft Azure Services", image: "/sectionconfig/azureservices.jpg", desc: "This project involved leveraging Microsoft Azure Services to build and optimize cloud-based solutions. It included deploying Azure Compute resources such as Virtual Machines (VMs) and Virtual Machine Scale Sets (VMSS) within Availability Zones to ensure high availability. Azure Networking features like Network Peering, Network Security Groups (NSG), and advanced connectivity (Hybrid Cloud and Site Recovery) were implemented. Storage solutions utilized Azure Storage Accounts, Data Lake, and design patterns for scalability. Applications were developed using App Services, including Web, API, Mobile, Logic, and Function Apps, supported by Azure Key Vault for secure secrets management. Azure DevOps enabled CI/CD pipelines, while Kubernetes Services orchestrated containerized workloads. Security and governance were enhanced with Azure AD and RBAC, and monitoring leveraged Azure Insights and messaging services like Event Hubs, Queues, and Bus.", link: "/", hasicon: false, icon: "" },
        { id: 6, title: "AWS", tags: "AWS", image: "/sectionconfig/amozan.jpeg", desc: "Recently, a project was delivered leveraging Amazon Web Services (AWS) to modernize and scale a cloud-based application. The project utilized Amazon EC2 for compute capacity and Elastic Load Balancing to ensure high availability. Amazon S3 provided scalable object storage, while AWS Lambda enabled serverless execution for event-driven workloads. Amazon RDS was implemented for a managed relational database, and DynamoDB for NoSQL requirements. AWS IAM ensured secure access control, and CloudWatch offered real-time monitoring and alerts. The solution was optimized with CloudFormation for infrastructure as code and Auto Scaling for cost-efficient performance. This resulted in improved agility, reliability, and scalability.", link: "/", hasicon: false, icon: "" },
        { id: 7, title: "Security", tags: "Cloud Security", image: "/sectionconfig/sec.jpg", desc: "Cloud Security Services are specialized practices, tools, and strategies designed to protect cloud-based systems, data, and applications from security threats. They ensure the confidentiality, integrity, and availability of resources in cloud environments, we are experts in Identity and Access Management (IAM),  Data Encryption, Threat Detection and Monitoring, Compliance Management, Network Security, Endpoint Protection, and Backup and Disaster Recovery.", link: "/", hasicon: false, icon: "" },
        { id: 8, title: "Cloud Storage", tags: "Cloud Storage", image: "/sectionconfig/storage.jpg", desc: "Azure Cloud Storage products have successfully demonstrated secure, scalable, and versatile storage solutions for businesses across various industries, Diverse Storage Options Successful implementation of object, file, disk, queue, and table storage tailored to business needs. Hybrid Data Storage Seamless integration of on-premises and cloud storage, enabling flexibility and scalability. Enhanced Security Adoption of advanced encryption and access control mechanisms to protect sensitive data. Optimization Strategies Utilization of technical resources to fine-tune storage performance and cost-efficiency. Scalable Solutions Proven ability to handle large-scale data workloads with consistent performance.", link: "/", hasicon: false, icon: "" },
        { id: 9, title: "Serverless Technology", tags: "Serverless Computing", image: "/sectionconfig/serverless.jpg", desc: "Serverless computing has evolved significantly, enabling developers to focus on building applications without managing server infrastructure. Serverless computing has recently experienced significant advancements, enhancing its capabilities and expanding its applications with Integration with Machine Learning, Enhanced Security Measures, Serverless Containers, Edge Computing Integration and Hybrid Cloud Environments. ", link: "/", hasicon: false, icon: "" },
    ]}},
    {component:"partners",data:{pagination:false,data:[
        { id: 1, image: "/img/micro.png", },
        { id: 2, image: "/businesssectionconfig/greatplace.jpg", },
    ]}},

]}
export default pagel