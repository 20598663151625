import React from 'react'
import config from '../config'
export default function Footer() {
    return (
        <div>

            <div className='footer wrapper'>
                {config.footer.map((item, index) => <div key={item.heading} className='item'>
                    <h3>{item.heading}</h3>
                    {item.children.map((x, i) => <a rel="noreferrer" href={x.link} key={i + index}>{x.title}</a>)}
                </div>)}
            </div>
            <div className='bottom  wrapper'>
                <div className='item'>
                    <p>Copyright © 2025 Saitpoint Limited - Reg: 07676081. All Rights Reserved.</p>
                </div>
                <div className='item'>
                    <span>Privacy Policy</span>{` `}
                    <span>Follow Saitpoint Limited</span>
                    <a rel="noreferrer" target="_blank" href="http://www.facebook.com"><i className='fa fa-facebook'></i></a>
                    <a rel="noreferrer" target="_blank" href="http://www.twitter.com"><i className='fa fa-twitter'></i></a>
                    <a rel="noreferrer" target="_blank" href="http://www.linkedin.com"><i className='fa fa-linkedin'></i></a>
                    <a rel="noreferrer" target="_blank" href="http://www.youtube.com"><i className='fa fa-youtube'></i></a>
                </div>
            </div>
        </div>
    )
}
