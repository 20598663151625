import React, { useState } from 'react';
import { usePagination } from '../utils/hooks'
export default function Cards(props) {
    let data = props.data
    const [start, setstart] = useState(0)
    const pp=6
    let pagination = usePagination(pp, data.data.length)
    const handleClick = num => setstart(num)

    if (props.data.pagination) {
        return (
            <div className='cards-container  wrapper'>
                <div className='wrapper' align="center">  <h1 className='h1'>{data.caption}</h1></div>
                <div className='cards'>
                    {data.data.slice(start, start + pp).map((x, i) => <div key={i} className='item'>
                        <img alt="best in country saitpoint.net"  src={x.image} />
                        <div className='pad'>
                            <h3>{x.title}</h3>
                            <span className='cards-tags'>{x.tags}</span>
                            <p>{x.desc}</p>
                            {/* {x?.href && <a target={"_blank"} href={x.href} className='read-more'>{x.href}</a>} */}
                            <a className='read-more' rel="noreferrer" target="_blank" href={x.href}>read more</a>
                        </div>
                    </div>)}
                </div>
                <div className='pagination'>
                    {pagination.map(x => <button onClick={e => handleClick(x * pp)} className={start === x * pp ? 'pagination-item active' : 'pagination-item'}>{x + 1}</button>)}
                </div>
            </div>
        );
    }
    else {
        return (
            <div>
                <div className='cards'>
                    {data.data.map((x, i) => <div key={i} className='item'>
                        <img alt="best in country saitpoint.net"  src={x.image} />
                        <div className='pad'>
                            <h3>{x.title}</h3>
                            <span className='cards-tags'>{x.tags}</span>
                            <p>{x.desc}</p>
                            <a rel="noreferrer" className='read-more' target="_blank" href={x.href}>read more</a>
                        </div>
                    </div>)}
                </div>
            </div>
        );
    }
}