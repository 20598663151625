const a= [
    {
        component: "tabs", data: {
            title: "Medical Science",
            p: "The Medical Science IT projects we handle are designed to streamline processes in Regulatory Affairs, ensuring seamless management of compliance across global markets. These projects cater to key areas such as Ad Promo (advertising and promotional material review), Labeling (product labeling accuracy and regulatory conformity), and Document Processing (efficient handling and archiving of critical regulatory documents). Additionally, they support International Regulatory Affairs by addressing the complexities of diverse global regulatory landscapes and ensuring Compliance with ever-evolving standards and requirements.",
            tabscontent: [
                { id: 1, name: "Regulatory Affairs", elements: ["International RA is responsible for monitoring the regulatory environment to help Saitpoint make sound strategic decisions in order to prepare and progress submissions to regulatory authorities in International territories for the whole product lifecycle, from clinical trials through to marketing authorization applications and post-approval changes such as new therapeutic indications.", ""] },
                { id: 2, name: "Doc Processing", elements: ["Document processing within IT projects at Saitpoint Limited focuses on automating and streamlining the management of various documents to enhance efficiency and compliance. This involves the use of Intelligent Document Processing (IDP) technologies, which leverage artificial intelligence (AI), machine learning (ML), and natural language processing (NLP) to automate complex document onboarding and processing tasks.", "IDP systems are capable of: Classifying documents based on their content and structure, such as invoices, legal notices, or research reports. Extracting and indexing data from various document formats, including PDFs and scanned images. Validating extracted data by cross-referencing with databases or predefined rules to ensure accuracy. Processing data for purposes like electronic delivery, translation, or integration into enterprise systems. By implementing these technologies, Saitpoint aims to reduce manual effort, minimize errors, and ensure timely processing of critical documents, thereby supporting the company's commitment to innovation and operational excellence.", ""] },
                { id: 3, name: "Ad Promo", elements: ["The Medical Sciences Department is dedicated to ensuring that all advertising and promotional (Ad Promo) materials for its pharmaceutical products are accurate, balanced, and compliant with regulatory standards. To support this commitment, Saitpoint implements IT projects that streamline the review and approval processes for Ad Promo content. These projects often involve the development of digital asset management systems and workflow automation tools that facilitate collaboration among marketing, legal, and regulatory teams. By leveraging technology, Saitpoint enhances efficiency, reduces the risk of non-compliance, and ensures that all promotional materials meet the highest standards of integrity and transparency.", ""] },
                { id: 4, name: "Labeling", elements: ["In the pharmaceutical industry, precise and compliant product Labeling is crucial for patient safety and regulatory adherence. IT projects focusing on Labeling aim to streamline the creation, review, and management of product labels to ensure accuracy and compliance with global regulations. These projects often involve developing systems that automate label generation, incorporate real-time regulatory updates, and facilitate collaboration among cross-functional teams. By implementing such IT solutions, pharmaceutical companies can enhance efficiency, reduce errors, and maintain compliance across diverse markets.", ""] },
                { id: 5, name: "Compliance", elements: ["Audit Management, Clinical Compliance, Electronic Systems Compliance(ESC), Quality Documents & Training, Administration(QDTA)",           "Audit Management: Audit Management, a sub-function of Regulatory Compliance (RC), supports RC activities by providing audit response coordination, management of audit and inspection-related information and records, audit and inspection metrics, contracts and requisitions for consultant auditors, and administration of the GTrack application for RC Audit.", ""] },
                { id: 6, name: "CMC", elements: ["In pharmaceutical development, Chemistry, Manufacturing, and Controls (CMC) are essential for ensuring drug quality and regulatory compliance. IT projects in this domain focus on digitizing and streamlining CMC processes to enhance efficiency and data integrity. These initiatives often involve implementing digital platforms that integrate data analytics, automation, and cloud-based systems to manage the entire drug development lifecycle. By adopting such digital CMC solutions, companies can optimize workflows, ensure consistent product quality, and accelerate time-to-market", ""] },
            ]
        }
    },
    {
        component: "partners", data: {
            data: [
                { id: 1, image: "/img/micro.png", },
                { id: 2, image: "/businesssectionconfig/greatplace.jpg", },
            ]
        }
    },
]


export default a 