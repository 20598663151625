const a= [
    {
        component: "cards", data: {
            caption: "cat1",
            pagination:true,
            data:[
                { id: 1, title: "Regulatory Operations", tags: "Medical Science", image: "/sectionconfig/medical.jpg", desc: 'Medical science is generally divided into areas of specialisation, such as anatomy, physiology and pathology with some biochemistry, microbiology, molecular biology and genetics.', link: "https://www.google.co.uk/", hasicon: true, icon: "" },
                { id: 2, title: "Client stories", tags: "Client stories", image: "/sectionconfig/client stories.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 3, title: "Meet our people", tags: "Meet our people", image: "/sectionconfig/meetourpeople.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 4, title: "Case studies & stories", tags: "Case studies & stories", image: "/sectionconfig/case.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 5, title: "Cloud Journey", tags: "Cloud Journey", image: "/sectionconfig/cloud journey.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 6, title: "my-caption", tags: "Voices of Change", image: "/sectionconfig/voices.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 7, title: "Capabilities", tags: "Capabilities", image: "/sectionconfig/cap.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 8, title: "Our Operations", tags: "What We Do", image: "/sectionconfig/whatwedo.jpg", desc: 'IT product and solutions company for the Utility, Telecom and Public sector', link: "/", hasicon: false, icon: "" },
                { id: 9, title: "Application", tags: "Blogs", image: "/sectionconfig/blog.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
            ]
        }
    },
    {
        component: "partners", data: {
            data: [
                { id: 1, image: "https://www.winwire.com/wp-content/uploads/2017/12/micro.png", },
                { id: 2, image: "/businesssectionconfig/greatplace.jpg", },
            ]
        }
    },
    ]
export default a