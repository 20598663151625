import axios from 'axios';
import React from 'react';
export default function Form1(props) {
    const { fields, title, desc, submitLabel } = props.data.data
    const [state, setstate] = React.useState({
        fn: "", ln: "", email: "", phone: "", country: "", message: ""
    })

    const sendEmail = (e) => {
        e.preventDefault();

        let fn = state["fn"]
        let ln = state["ln"]
        let email = state["email"]
        let phone = state["phone"]
        let country = state["country"]
        let message = state["message"]

        let name = fn + " " + ln

        let url = `http://mail.saitpoint.net/index.php?name=${name}&email=${email}&phone=${phone}&message=${message}&country=${country}`
        axios.get(url)
            .then(res => res.data)
            .then(d => console.log("lkj-d", d))
            .catch(d => console.log("lkj-e", d))
    };

    const handleChange = e => {
        let { name, value } = e.target
        setstate({ ...state, [name]: value })
    }

    return (
        <div className='form1-container'>
            <div className='form1'>
                <h1>{title}</h1>
                <p>{desc}</p>
                {fields.map((item, index) => <div className='form1-flex'>
                    <strong>{item.label}</strong>
                    {item.type === "text" ? <input value={state[item.name]} onChange={handleChange} className={item.name} name={item.name} /> : <textarea name={item.name} onChange={handleChange} className={item.name} value={state[item.name]}></textarea>}
                </div>)}
                <div className='form1-btn'>
                    <button onClick={sendEmail}>{submitLabel}</button>
                </div>
            </div>
        </div>
    )
}
