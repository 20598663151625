import React, { useState } from 'react';
import { usePagination } from '../utils/hooks'
export default function Cards3(props) {
    let data = props.data
    const [start, setstart] = useState(0)
    const pp=6

    let pagination = usePagination(pp, data.data.length)
    const handleClick = num => setstart(num)

    const renderItems = (x, i) => {
        return <div key={i} className={i % 2 === 0 ? 'item active' : "item"}>
            <div className='card3-item l'>
                <img alt="best in country saitpoint.net"  src={x.image} />
            </div>
            <div className='pad card3-item r'>
                <h3>{x.title}</h3>
                {/* <span className='cards-tags'>{x.tags}</span> */}
                <p>{x.desc}</p>
                {/* {x?.href && <a target={"_blank"} href={x.href} className='read-more'>{x.href}</a>} */}
                {/* <a target="_blank" href={x.href}>{x.href}</a> */}
            </div>
        </div>
    }

    if (props.data.pagination) {
        return (
            <div className='cards3-container  wrapper'>
                <div align="center">  <h1 className='h1'>{data.caption}</h1></div>
                <div align="center">  <h2 className='h2'>{data?.d||"Saitpoint Limited"} </h2></div>
                <div className='cards3'>
                    {data.data.slice(start, start + pp).map((x, i) => renderItems(x, i))}
                </div>
                <div className='pagination'>
                    {pagination.map(x => <button onClick={e => handleClick(x * pp)} className={start === x * pp ? 'pagination-item active' : 'pagination-item'}>{x + 1}</button>)}
                </div>
            </div>
        );
    }
    else {
        return (
            <div className='cards3-container  wrapper'>
                <div align="center">  <h1 className='h1'>{data.caption}</h1></div>
                <div align="center">  <h2 className='h2'>{data?.d||"Saitpoint Limited"} </h2></div>
                <div className='cards3'>
                    {data.data.map((x, i) => renderItems(x, i))}
                </div>
            </div>
        );
    }
}