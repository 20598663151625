import React, { useState } from 'react';
import { usePagination } from '../utils/hooks'
export default function Cards6(props) {
    let data = props.data
    const [start, setstart] = useState(0)
    const pp=6
    let pagination = usePagination(pp, data.data.length)
    const handleClick = num => setstart(num)
   
  const [isExpanded, setIsExpanded] = useState(false);
  
    if (props.data.pagination) {
        return (
            <div className='cards6-container  wrapper'>
                <div className='wrapper' align="center">  <h1 className='h1'>{data.title}</h1></div>
                <div className='wrapper' align="center">  <p className='p'>{data.desc}</p></div>
                <div className='cards6'>
                    {data.data.slice(start, start + pp).map((x, i) => <div key={i} className='item'>
                        {/* <img alt="best in country saitpoint.net"  src={x.image} /> */}
                        <div className='pad'>
                            <h3>{x.title}</h3>
                            <span className='cards6-tags'>{x.tags}</span>
                            <p dangerouslySetInnerHTML={{ 
              __html: 
            x.shortdesc}}
          />
          {x.desc.length > 200 && (
            <button className='read-more'  onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          )}
          {isExpanded && (
            <div dangerouslySetInnerHTML={{ __html: x.desc }} />
          )}
                            {/* {x?.href && <a target={"_blank"} href={x.href} className='read-more'>{x.href}</a>} */}
                            {/* <a   rel="noreferrer"  className='read-more' target="_blank" href={x.href}>read more</a> */}
                        </div>
                    </div>)}
                </div>
                <div className='pagination'>
                    {pagination.map(x => <button onClick={e => handleClick(x * pp)} className={start === x * pp ? 'pagination-item active' : 'pagination-item'}>{x + 1}</button>)}
                </div>
            </div>
        );
    }
    else {
        return (
            <div className='cards6-container  wrapper'>
                <div className='wrapper' align="center">  <h1 className='h1'>{data.title}</h1></div>
                <div className='wrapper' align="center">  <p className=''>{data.desc}</p></div>
                <div className='cards6'>
                    {data.data.map((x, i) => <div key={i} className='item'>
                        <img alt="best in country saitpoint.net"  src={x.image} />
                        <div className='pad'>
                            <h3>{x.title}</h3>
                            <span className='cards6-tags'>{x.tags}</span>
                            <p>{x.desc}</p>
                            <a  rel="noreferrer" className='read-more' target="_blank" href={x.href}>read more</a>
                        </div>
                    </div>)}
                </div>
            </div>
        );
    }
}