let htmltemplatepost1 = `
    <div class="template" >
    <h1>WinWire Wins Microsoft AI ML Workathon 2021</h1>
    <div style="display:flex;align-items:center; justify-content:space-between;">
        <div style="display:flex;align-items:center; justify-content:space-between;">
            <img alt="best in country saitpoint.net"  style="margin-right:20px" class="circle width="30" src="https://www.winwire.com/wp-content/uploads/2020/08/Viroo.png"/>
            <small>VirooPax</small>    
        </div>    
        <div>
            <i style="color:red" class="fa fa-calendar"></i>
            Jan 11,2022
        </div>    
        
    </div>
    <p>
    I am excited to share that WinWire has won <b>Microsoft AI ML Workathon 2021.</b> The solution we created for the Workathon is an Azure-enabled WinVision AI Animal Health solution.
    </p>
        <p>
    Healthcare organizations are increasingly focusing on the availability of data to make informed decisions and in the process to make a difference to the world. This is the underlying concept behind WinWire’s winning Automated Animal Health Intelligence solution. The Vision AI/ML driven solution that displays compassion and inclusivity to the animal world was declared a winner at the Microsoft’s AI ML Workathon 2021, an exclusive event for 80+ Microsoft Partners across Services, ISV, ITeS, and 100+ teams recruited from all over India.
    </p>
    <div align="center">
        <img alt="best in country saitpoint.net"  width="200" align="middle" src="https://www.winwire.com/wp-content/uploads/2022/01/AI-ML-Workathon-2021-1.png"/>
    </div>
    <p>
    I am excited to share that WinWire has won <b>Microsoft AI ML Workathon 2021.</b> The solution we created for the Workathon is an Azure-enabled WinVision AI Animal Health solution.
    </p>
    <h1>WinWire Wins Microsoft AI ML Workathon 2021</h1>
    <p>
    Healthcare organizations are increasingly focusing on the availability of data to make informed decisions and in the process to make a difference to the world. This is the underlying concept behind WinWire’s winning Automated Animal Health Intelligence solution. The Vision AI/ML driven solution that displays compassion and inclusivity to the animal world was declared a winner at the Microsoft’s AI <a rel="noreferrer" href="#">ML Workathon 2021</a>, an exclusive event for 80+ Microsoft Partners across Services, ISV, ITeS, and 100+ teams recruited from all over India.
    </p>
    <h1>WinWire Wins Microsoft AI ML Workathon 2021</h1>
    <li>Enables livestock farmers to dramatically reduce the rate of unanticipated cattle and poultry losses that occur, resulting in massive economic gains.</li>
    <li>Enables livestock farmers to dramatically reduce the rate of unanticipated cattle and poultry losses that occur, resulting in massive economic gains.</li>
    <li>Enables livestock farmers to dramatically reduce the rate of unanticipated cattle and poultry losses that occur, resulting in massive economic gains.</li>
    <li>Enables livestock farmers to dramatically reduce the rate of unanticipated cattle and poultry losses that occur, resulting in massive economic gains.</li>
    <div class="t-flex">
        <div>
            <img alt="best in country saitpoint.net"  width="30" src="https://www.winwire.com/wp-content/uploads/2020/08/Viroo.png"/>
            <small>VirooPax</small>    
        </div>    
        <div>
        </div>    
        
    </div>
    <h1>Share Your Thoughts</h1>
    <p>Your email address will not be published. Required fields are marked </p>
    <textarea rows="5" cols="100">Comment *</textarea>
    <input class="c-input" placeholder="name *" />
    <input class="c-input" placeholder="email *" />
    <button>comment</button>
    </div>
    
    `

export default htmltemplatepost1