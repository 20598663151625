import React  from 'react';
import { Link } from 'react-router-dom';
export default function Business(props) {
    let { title, data } = props.data
    return (
        <div className=' wrapper'>
            <div className='business'>
                <h1 className='h1'>{title}</h1>
                <div className='flex'>
                    {data.map((x, i) => <div key={i} className='item'>
                        <Link to={x.to}>
                            <img alt="best in country saitpoint.net"  src={x.image} height={50} />
                            <h3>{x.title}</h3>
                            <p>{x.description}</p>
                        </Link>
                    </div>)}
                </div>
            </div>
        </div>
    );
}