import landing from './page-layouts/landing'
import Celebrating from './page-layouts/Celebrating'
import ourcompany from './page-layouts/ourcompany'
import purpose from './page-layouts/purpose'
import contact from './page-layouts/contact'
import Jobs from './page-layouts/Jobs'
import Learning from './page-layouts/cloud'
import cloud from './page-layouts/Learning'
import medical from './page-layouts/medical'
import government from './page-layouts/government'
import Diversity from './page-layouts/Diversity'
import Employee from './page-layouts/Employee'
import company from './page-layouts/company'
import notifications from './page-layouts/notifications'
const pagelayout=[
    landing,
    contact,
    ourcompany,
    Celebrating,
    purpose,
    Jobs,
    Learning,
    cloud,
    medical,
    government,
    Diversity,
    Employee,
    company,
    notifications
]
export default pagelayout