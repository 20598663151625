import React from "react";
import Slider from "react-slick";
export default function Slider3({data}) {
    var settings = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,

    };
    return (
        <Slider {...settings} >
            {data.map((slide, index) => <div className="slider3-container">
                <h1>{slide.title}</h1>
                <h2>{slide.heading}</h2>
                <img alt="best in country saitpoint.net"  src={slide.image} />
                <p className="legend">{slide.p}</p>
                <br/>
                <br/>
                <br/>
                <p className="date">{slide.date} : <i className="fa fa-calendar"></i></p>
            </div>
            )}
        </Slider>
    );
}