const a={name:"landing",components:[
    {component:"banner",data:{
        title:"Awards and Recognitions                ",
        description:"Company accolades                ",
        image:"/sectionconfig/landingpage.png"
    }},
    {component:"cards",data:{pagination:false,data:[
        { id: 1, title: "Medical Science", tags: "Medical Science", image: "/sectionconfig/medical.jpg", desc: '"Medical Science, CMC, Regulatory, Labeling, and Ad Promo" encompasses a comprehensive suite of services supporting pharmaceutical and healthcare organizations. From Chemistry, Manufacturing, and Controls (CMC) to regulatory compliance, product labeling, and advertising promotion, we ensure precision, adherence to industry standards, and streamlined processes to bring safe and effective products to market efficiently.', link: "/", hasicon: false, icon: "" },
        { id: 2, title: "Client stories", tags: "Client stories", image: "/sectionconfig/client stories.jpg", desc: "'Client Stories' at an IT consulting company showcases the transformative solutions delivered to businesses across industries. These narratives highlight Saitpoint Limited's ability to address challenges, optimize systems, and drive client innovation. Saitpoint Limited demonstrates its expertise, technical skills, and customer-centric approach by sharing detailed success stories. These stories inspire confidence, showcasing measurable outcomes like cost savings, improved efficiency, and enhanced digital transformation.", link: "/", hasicon: false, icon: "" },
        { id: 3, title: "Meet our people", tags: "Meet our people", image: "/sectionconfig/meetourpeople.jpg", desc: 'Meet Our People offers a glimpse into the talented professionals driving our IT consulting success. With diverse expertise, innovative thinking, and a passion for solving complex challenges, our team is dedicated to delivering exceptional results. Please get to know the individuals who bring ideas to life and make a difference for our clients daily.', link: "/", hasicon: false, icon: "" },
        { id: 4, title: "Case studies & stories", tags: "Case studies & stories", image: "/sectionconfig/case.jpg", desc: 'Case Studies and Stories highlight our proven track record of delivering impactful IT solutions to clients across industries. Each case study delves into real-world challenges, innovative approaches, and measurable results, showcasing our expertise and commitment to excellence. These stories reflect the value we bring by empowering businesses to achieve their goals through tailored technology strategies', link: "/", hasicon: false, icon: "" },
        { id: 5, title: "The Cloud Journey", tags: "Cloud Journey", image: "/sectionconfig/cloud journey.jpg", desc: '"Cloud Journey" captures our expertise in guiding businesses through seamless cloud transformations. From strategy to implementation, we help organizations harness the power of cloud technology to enhance agility, scalability, and innovation. Our tailored approach ensures a smooth transition, empowering clients to unlock the full potential of the cloud.', link: "/", hasicon: false, icon: "" },
        { id: 6, title: "Voices of Change", tags: "Voices of Change", image: "/sectionconfig/voices.jpg", desc: '"Voices of Change" showcases the transformative impact of technology through the perspectives of those leading and experiencing innovation. These stories highlight how our IT consulting solutions empower individuals and organizations to embrace change, drive progress, and create lasting value in an ever-evolving digital landscape.', link: "/", hasicon: false, icon: "" },
        { id: 7, title: "Capabilities", tags: "Capabilities", image: "/sectionconfig/cap.jpg", desc: 'Capabilities highlights our broad range of IT consulting services tailored to meet diverse business needs. From strategy and innovation to implementation and support, we deliver solutions that drive efficiency, scalability, and success. Our expertise empowers organizations to navigate challenges and achieve their digital transformation goals.', link: "/", hasicon: false, icon: "" },
        { id: 8, title: "Operations", tags: "What We Do", image: "/sectionconfig/whatwedo.jpg", desc: 'Our Operations reflects our commitment to delivering innovative IT solutions through seamless project execution and cutting-edge technology. We specialize in custom application development, IT product creation, and strategic consulting to empower businesses with efficient, scalable, and transformative solutions', link: "/", hasicon: false, icon: "" },
        { id: 9, title: "Applications", tags: "Blogs", image: "/sectionconfig/blog.jpg", desc: "Our Applications showcases our expertise in delivering custom IT products, tools, and comprehensive implementation services. We design innovative software solutions that address business challenges, streamline operations, and enhance performance, ensuring seamless integration and effective deployment to meet your organization's unique needs.", link: "/", hasicon: false, icon: "" },
    ]}},
    {component:"partners",data:{pagination:false,data:[
        { id: 1, image: "/img/micro.png", },
        { id: 2, image: "/businesssectionconfig/greatplace.jpg", },
    ]}},

]}
export default a 