const pagel= {name:"medical",components:[
    {component:"banner",data:{
        title:"Learning and Recognitions                ",
        description:"Company accolades                ",
        image:"/sectionconfig/medicalpres.jpg"
    }},
    {component:"cards",data:{pagination:false,data:[
        { id: 1, title: "Regulatory Affairs", tags: "Regulatory Affairs", image: "/sectionconfig/regaffai.jpg", desc: "International RA is responsible for monitoring the regulatory environment to help Saitpoint make sound strategic decisions in order to prepare and progress submissions to regulatory authorities in International territories for the whole product lifecycle, from clinical trials through to marketing authorization applications and post-approval changes such as new therapeutic indications.", link: "/", hasicon: false, icon: "" },
        { id: 2, title: "Doc Processing", tags: "Doc Processing", image: "/sectionconfig/docproc.jpg", desc: "Document processing at Saitpoint Limited leverages Intelligent Document Processing (IDP) technologies to automate and streamline document management. Using AI, ML, and NLP, IDP systems classify, extract, validate, and process data from diverse formats like PDFs and scanned images. These technologies reduce manual effort, minimize errors, and ensure timely processing. This supports Saitpoint's commitment to innovation and operational excellence.", link: "/", hasicon: false, icon: "" },
        { id: 3, title: "International RA", tags: "International RA", image: "/sectionconfig/regaffairs.jpg", desc: "Saitpoint implement IT projects to streamline international regulatory affairs, ensuring compliance across diverse markets. These initiatives enhance global regulatory submissions, monitor compliance, and manage product registrations efficiently. By leveraging advanced technologies, both companies maintain adherence to international standards, facilitating the global distribution of their pharmaceutical products.", link: "/", hasicon: false, icon: "" },
        { id: 4, title: "Clinical Operations", tags: "Clinical Operations", image: "/sectionconfig/clinicalops.png", desc: "Saitpoint have undertaken IT projects to enhance clinical operations through digital solutions. These initiatives focus on automating clinical trial management, improving data accuracy, and streamlining patient recruitment processes. By implementing advanced technologies, both companies aim to accelerate clinical development timelines and ensure compliance with regulatory standards. These efforts reflect a commitment to innovation and operational excellence in clinical research.", link: "/", hasicon: false, icon: "" },
        { id: 5, title: "Regulatory Operations", tags: "Regulatory Operations", image: "/sectionconfig/regops.jpg", desc: "Saitpoint have successfully implemented IT projects to optimize Regulatory Operations. These projects focus on automating regulatory submission processes, managing compliance documentation, and improving the efficiency of global product registrations. By integrating advanced technologies, such as cloud-based platforms and AI tools, both companies have streamlined workflows, reduced manual tasks, and enhanced regulatory compliance, ensuring faster and more accurate regulatory approvals worldwide.", link: "/", hasicon: false, icon: "" },
        { id: 6, title: "CMC", tags: "Chemical Manufacturing and Control", image: "/sectionconfig/cmc.jpg", desc: "In pharmaceutical development, Chemistry, Manufacturing, and Controls (CMC) are essential for ensuring drug quality and regulatory compliance. IT projects in this domain focus on digitizing and streamlining CMC processes to enhance efficiency and data integrity. These initiatives often involve implementing digital platforms that integrate data analytics, automation, and cloud-based systems to manage the entire drug development lifecycle. By adopting such digital CMC solutions, companies can optimize workflows, ensure consistent product quality, and accelerate time-to-market", link: "/", hasicon: false, icon: "" },
        { id: 7, title: "Compliance", tags: "Compliance", image: "/sectionconfig/voices.jpg", desc: "Enhancing compliance management by integrating tools and processes across key compliance domains. The system is designed to streamline audit management, facilitate the coordination of audit responses, manage inspection-related data, and generate metrics. It also includes support for Clinical Compliance and Electronic Systems Compliance (ESC) to ensure adherence to regulatory standards in clinical and digital environments. Additionally, the project addresses the Quality Documents & Training Administration (QDTA) by enabling efficient management of training programs and quality documentation. Features such as audit metrics tracking, consultant auditor contract management, and the administration of the GTrack application for regulatory compliance audits are incorporated to ensure a seamless and efficient compliance ecosystem.", link: "/", hasicon: false, icon: "" },
        { id: 8, title: "Ad-Promo", tags: "Ad-Promo", image: "/sectionconfig/adpromo.jpg", desc:"The Medical Sciences Department is dedicated to ensuring that all advertising and promotional (Ad Promo) materials for its pharmaceutical products are accurate, balanced, and compliant with regulatory standards. To support this commitment, Saitpoint implements IT projects that streamline the review and approval processes for Ad Promo content. These projects often involve the development of digital asset management systems and workflow automation tools that facilitate collaboration among marketing, legal, and regulatory teams. By leveraging technology, Saitpoint enhances efficiency, reduces the risk of non-compliance, and ensures that all promotional materials meet the highest standards of integrity and transparency.", link: "/", hasicon: false, icon: "" },
        { id: 9, title: "Labeling", tags: "Labeling", image: "/sectionconfig/labeling.jpg", desc: "In the pharmaceutical industry, precise and compliant product Labeling is crucial for patient safety and regulatory adherence. IT projects focusing on Labeling aim to streamline the creation, review, and management of product labels to ensure accuracy and compliance with global regulations. These projects often involve developing systems that automate label generation, incorporate real-time regulatory updates, and facilitate collaboration among cross-functional teams. By implementing such IT solutions, pharmaceutical companies can enhance efficiency, reduce errors, and maintain compliance across diverse markets.", link: "/", hasicon: false, icon: "" },
    ]}},
    {component:"partners",data:{pagination:false,data:[
        { id: 1, image: "/img/micro.png", },
        { id: 2, image: "/businesssectionconfig/greatplace.jpg", },
    ]}},

]}
export default pagel