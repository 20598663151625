const pagel= {name:"government",components:[
    {component:"banner",data:{
        title:"Learning and Recognitions                ",
        description:"Company accolades                ",
        image:"/sectionconfig/govrn.jpg"
    }},
    {component:"cards",data:{pagination:false,data:[
        { id: 1, title: "Ministry of Defence", tags: "MoD", image: "/sectionconfig/defence.png", desc: "Our client, the Ministry of Defence (MOD), is responsible for safeguarding the security, interests, and resilience of the United Kingdom. Partnering with MOD, we provide advanced IT solutions, cybersecurity frameworks, and digital transformation services that enhance operational efficiency and mission readiness. Our collaboration ensures cutting-edge technology supports the defense sector's critical objectives", link: "https://des.mod.uk/", hasicon: false, icon: "" },
        { id: 2, title: "Department for Education", tags: "Department for Education", image: "/sectionconfig/dept.jpg", desc: "The Department for Education (DfE) is dedicated to creating opportunities for children and learners of all ages in England. As a client, the DfE benefits from our expertise in developing innovative IT solutions to enhance educational systems and streamline operations. We support their mission by delivering secure digital platforms, improving data management, and enabling efficient communication, ensuring better access to education and resources for learners and educators nationwide.", link: "/", hasicon: false, icon: "" },
        { id: 3, title: "NHS England", tags: "NHS England", image: "/sectionconfig/nhse.jpg", desc: "NHS England is a pivotal organization in the UK healthcare system, overseeing the delivery of high-quality medical services to millions across the nation. As a client, NHS England benefits from our expertise in IT solutions, digital transformation, and data-driven healthcare innovation. We support their mission by implementing advanced technologies, enhancing patient care, streamlining operations, and ensuring secure data management. Our collaboration empowers NHS England to meet evolving healthcare demands efficiently while maintaining a strong focus on patient outcomes and service excellence.", link: "/", hasicon: false, icon: "" },
        { id: 4, title: "Department for International Trade", tags: "Department for Business and Trade", image: "/sectionconfig/dfbt.png", desc: "The Department for Business and Trade (formerly Department for International Trade) drives economic growth by championing free trade and fostering global business relationships. As a client, we support their mission by providing cutting-edge IT solutions to streamline trade policy management and enhance the development of trade agreements. Our expertise helps optimize data analysis, secure communication platforms, and efficient workflows, empowering the department to deliver impactful trade policies and foster international economic partnerships.", link: "/", hasicon: false, icon: "" },
        { id: 5, title: "BAE Systems", tags: "BAE Systems", image: "/sectionconfig/baesystems.jpg", desc: "BAE Systems, a leading global defense, aerospace, and security company, partners with us to enhance their technological capabilities. We provide advanced IT solutions, cybersecurity frameworks, and digital transformation services to support their mission of delivering innovative and reliable products. Our collaboration ensures streamlined operations, enhanced security, and cutting-edge technologies that drive BAE Systems' commitment to protecting people and nations worldwide", link: "/", hasicon: false, icon: "" },
        { id: 6, title: "British Redcross", tags: "British Redcross", image: "/sectionconfig/br.jpg", desc: "The British Red Cross, a renowned humanitarian organization, partners with us to leverage technology in delivering critical support and services. We provide innovative IT solutions to enhance their operational efficiency, streamline resource management, and improve communication. Our collaboration empowers the British Red Cross to respond swiftly to emergencies, support vulnerable communities, and fulfill their mission of providing help and hope where it's needed most.", link: "/", hasicon: false, icon: "" },
        { id: 7, title: "Ministry Of Justice", tags: "MoJ", image: "/sectionconfig/moj.png", desc: "The Ministry of Justice (MoJ) implemented a Project Portfolio Management (PPM) solution using Microsoft Project Online, integrating it with their existing Microsoft 365 environment. This integration enabled single sign-on and role-based access control (RBAC), leveraging existing security and document access controls. The project involved migrating over 200 active projects to the new system, enhancing collaboration, data security, and reporting efficiency. RBAC is a security mechanism that restricts network access based on individual user roles, ensuring that users have access only to the information necessary for their duties. The MoJ's implementation of RBAC within the PPM solution improved data security by ensuring that users had appropriate access levels, streamlined project management processes, and enhanced reporting capabilities.", link: "/", hasicon: false, icon: "" },
        { id: 8, title: "Landmarc Support Services Limited", tags: "Landmarc", image: "/sectionconfig/land.jpg", desc: "Landmarc Support Services Limited provides project management services for military locations to the Ministry of Defence. SharePoint Server 2019 offers several enhancements that can benefit project management, including improved support for PowerApps and Flow, modern team sites, and enhanced lists and libraries. Integrating SharePoint 2019 with Microsoft 365 can further enhance collaboration and productivity by leveraging cloud-based tools and services. Implementing these technologies can streamline project management processes, improve communication, and provide a more efficient platform for managing projects across various locations. Landmarc Support Services has been awarded a £560 million contract by the UK's Defence Infrastructure Organisation (DIO) to manage the UK Defence Training Estate and IT. Landmarc's role is crucial in ensuring that military training facilities are well-maintained and capable of meeting the evolving needs of the UK's defence forces.", link: "/", hasicon: false, icon: "" },
        { id: 9, title: "Essex Police", tags: "Essex Police HQ ", image: "/sectionconfig/ephq.png", desc: "Essex Police, in collaboration with Kent Police, became the first UK forces to fully deploy Microsoft 365 under the National Enabling Programmes. This initiative enhanced collaboration and efficiency among officers and staff. In 2020, Essex Police engaged with Livingstone to optimize their Microsoft Enterprise Agreement, resulting in significant cost avoidance and a more efficient licensing approach. Additionally, Essex Police implemented Live Facial Recognition (LFR) technology to assist in locating individuals wanted for criminal offenses, thereby enhancing public safety. These projects demonstrate Essex Police's commitment to leveraging advanced technologies to improve operational effectiveness and public safety.", link: "/", hasicon: false, icon: "" },
    ]}},
    {component:"partners",data:{pagination:false,data:[
        { id: 1, image: "/img/micro.png", },
        { id: 2, image: "/businesssectionconfig/greatplace.jpg", },
    ]}},

]}
export default pagel