const a={name:"learning",components:[
    {component:"banner",data:{
        title:"Learning and Recognitions                ",
        description:"Company accolades                ",
        image:"/sectionconfig/learn1.png"
    }},
    {component:"cards",data:{pagination:false,data:[
        { id: 1, title: "Security Engineer", tags: "Cyber Security", image: "/sectionconfig/cyber.png", desc: 'At Saitpoint Limited, we provide robust cybersecurity solutions to safeguard your digital infrastructure. Our services include threat protection, advanced monitoring, and risk management strategies designed to prevent data breaches and cyberattacks. We implement Key Vault solutions to securely manage and store sensitive data, and offer DDoS (Distributed Denial of Service) protection to ensure continuous availability and performance. With our expertise, we help organizations strengthen their defenses, protect critical assets, and maintain compliance with the latest security standards.', link: "/", hasicon: false, icon: "" },
        { id: 2, title: "Test Engineer", tags: "Test Engineer", image: "/sectionconfig/testengg.png", desc: 'Our Test Engineering team specializes in cutting-edge technologies and methodologies to ensure software quality and reliability. With expertise in automated testing tools, performance analysis, and quality assurance frameworks, we deliver robust testing solutions that streamline development cycles, enhance system performance, and meet the highest industry standards.', link: "/", hasicon: false, icon: "" },
        { id: 3, title: "Data Science", tags: "Data Science", image: "/sectionconfig/datascience.png", desc: 'Data Science at Saitpoint Limited focuses on transforming raw data into actionable insights. Our experts leverage advanced analytics, machine learning, and AI technologies to uncover patterns, optimize decision-making, and drive innovation, empowering businesses with data-driven strategies for growth and success.', link: "/", hasicon: false, icon: "" },
        { id: 4, title: "Online Cloud", tags: "Cloud Journey", image: "/sectionconfig/case.jpg", desc: "We specialize in leveraging leading cloud platforms, including Azure, AWS, Google Cloud, and IBM Cloud, to deliver scalable and secure solutions. Whether it's infrastructure setup, application deployment, or advanced analytics, our expertise ensures seamless integration, cost efficiency, and optimized performance tailored to your business needs.", link: "/", hasicon: false, icon: "" },
        { id: 5, title: "DevOps Engineer", tags: "DevOps Engineer", image: "/sectionconfig/devopsengg.png", desc: "At Saitpoint Limited, our Azure and AWS DevOps experts streamline software development and deployment processes through automation, CI/CD pipelines, and infrastructure as code. With proficiency in cloud-native tools and best practices, they ensure efficient collaboration, scalability, and rapid delivery of high-quality solutions tailored to client needs.", link: "/", hasicon: false, icon: "" },
        { id: 6, title: "Software Engineer", tags: "Software Engineer", image: "/sectionconfig/software.png", desc: 'At Saitpoint Limited, our software engineering experts excel in leveraging advanced tools and technologies to deliver robust and scalable solutions. With deep proficiency in programming languages, frameworks, cloud platforms, and DevOps practices, we craft innovative software tailored to meet diverse business needs, ensuring efficiency, quality, and performance.', link: "/", hasicon: false, icon: "" },
        { id: 7, title: "Power BI Developer", tags: "Business Intelligence Developer", image: "/sectionconfig/pbd.png", desc: "Our Power BI experts at Saitpoint Limited specialize in transforming complex data into interactive and actionable visual insights. With expertise in dashboard creation, data modeling, and analytics, they empower organizations to make informed decisions. Project highlights include delivering customized reporting solutions that enhance business intelligence and drive measurable outcomes.", link: "/", hasicon: false, icon: "" },
        { id: 8, title: "Project Management", tags: "Project Management", image: "/sectionconfig/pms.png", desc: "At Saitpoint Limited, our project management experts excel in planning, executing, and delivering projects on time and within scope. With skills in Agile, Scrum, and traditional methodologies, they ensure seamless coordination and stakeholder alignment. Project highlights include driving complex IT implementations and achieving impactful business transformations.", link: "/", hasicon: false, icon: "" },
        { id: 9, title: "AI Development Platforms", tags: "AI Automation", image: "/sectionconfig/ai.jpg", desc: "At Saitpoint Limited, we bring cutting-edge expertise in AI, Machine Learning (ML), and Natural Language Processing (NLP) to drive innovation across industries. Our team leverages advanced ML algorithms and NLP techniques to extract meaningful insights from data, automate processes, and enhance customer experiences. We also specialize in AI-driven data analysis and business intelligence, enabling organizations to unlock the full potential of their data through predictive analytics, real-time decision-making, and customized reporting solutions. Our solutions empower businesses to stay ahead with actionable intelligence and improved operational efficiency.", link: "/", hasicon: false, icon: "" },
    ]}},
    {component:"partners",data:{pagination:false,data:[
        { id: 1, image: "/img/micro.png", },
        { id: 2, image: "/businesssectionconfig/greatplace.jpg", },
    ]}},

]}
export default a 